<template>
  <div id="app">
    <router-view />
    <treeTansfer />
  </div>
</template>

<script>
import treeTansfer from "@/components/treeTransfer.vue";
export default {
  components: {
    treeTansfer: treeTansfer
  }
};
</script>
<style lang="scss">
#app {
      height: 100vh;
  /*全局滚动条样式*/
  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
  }
  /*正常情况下滑块的样式*/
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
  }
  /*鼠标悬浮在该类指向的控件上时滑块的样式*/
  :hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
  }
  /*鼠标悬浮在滑块上时滑块的样式*/
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
  }
  /*正常时候的主干部分*/
  ::-webkit-scrollbar-track {
    border-radius: 7px;
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0);
    background-color: (0, 0, 0, 0.5);
    display: none;
  }
  /*鼠标悬浮在滚动条上的主干部分*/
  ::-webkit-scrollbar-track:hover {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.01);
  }
}

</style>
