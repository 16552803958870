import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Loading from "./components/loading.js";
// import './registerServiceWorker'

import "./assets/clear.scss";

// 引入地图map
import "echarts/map/js/china";

// 引入粒子特效 vue-particles
import VueParticles from 'vue-particles';
Vue.use(VueParticles);

/*
    引入element-ui表单验证
*/
import rules from "./assets/rules.js";
Vue.prototype.$rules = rules;
/*通用方法*/
import utils from "./assets/general.js";
Vue.prototype.$utils = utils;

// 引入vue-amap
// import VueAMap from 'vue-amap';
// Vue.use(VueAMap);
// 初始化vue-amap
// VueAMap.initAMapApiLoader({
//   // 高德的key
//   key: '255a1b5df167e030ec0807d02bc8d589',
//   // 插件集合
//   plugin: [
//     'AMap.Autocomplete',
//     'AMap.PlaceSearch',
//     'AMap.Scale',
//     'AMap.OverView',
//     'AMap.ToolBar',
//     'AMap.MapType',
//     'AMap.PolyEditor',
//     'AMap.CircleEditor',
//   ],
//   // 高德 sdk 版本，默认为 1.4.4
//   v: '1.4.4',
// });

import print from "./assets/print.js";
Vue.use(print);
/*
    引入element-ui
*/
import element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(element);
Vue.use(Loading);

/*电子档案全局信息组件*/
import headInformation from "./views/management/eRecord/headInformation";

Vue.component("headInformation", headInformation);
/*
    引入post数据处理插件   qs

    用法
    axios.post('xxx',this.$qs({
        a:1
    }))
*/
import qs from "qs";
Vue.prototype.$qs = qs.stringify;
/*
    引入axios
*/
import axios from "axios";
Vue.prototype.$axios = axios;

/*
    设置默认接口地址
*/
axios.defaults.baseURL = process.env.NODE_ENV == "development" ? "/" : store.state.url; //默认接口地址
axios.defaults.withCredentials = true; //允许axios请求携带cookie等凭证
/*
    引入echarts
    调用
        this.$echarts
    为了提升加载速度，进行按需引入
    按需引入参考文档
        https://juejin.im/post/5ac9c3ae6fb9a028d2083531
        https://github.com/apache/incubator-echarts/blob/master/index.js
*/

/*引入echarts主要模块*/
import echarts from "echarts/lib/echarts";

import "./assets/echartsConfig.js";

Vue.prototype.$echarts = echarts;

/**  数据回筛 */
Vue.prototype.$dataBack = (data, item, u_name, u_value) => {
  let type = true
  data.forEach(itm => {
    if (itm[u_name] == item[u_name]) type = false
  })
  if (type) {
    data.push(item)
  }
  return data
}

/*
    限制输入数字
*/
Vue.directive("enterNumber", {
  inserted(el) {
    el.addEventListener("keypress", function (e) {
      e = e || window.event;
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      let charcode = typeof e.charCode === "number" ? e.charCode : e.keyCode;
      let re = /\d/;
      if (
        !re.test(String.fromCharCode(charcode)) &&
        charcode > 9 &&
        !e.ctrlKey
      ) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    });
  }
});
/* 限制40长度 */
Vue.prototype.$sishiLength = item => {
  var realLength = 0;
  var len = item.length;
  var charCode = -1;
  for (var i = 0; i < len; i++) {
    charCode = item.charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) {
      realLength += 1;
    } else {
      // 如果是中文则长度加2
      realLength += 2;
    }
  }
  if (realLength > 40) {
    return false;
  } else {
    return true;
  }
};

/* 限制80长度 */
Vue.prototype.$bashiLength = item => {
  var realLength = 0;
  var len = item.length;
  var charCode = -1;
  for (var i = 0; i < len; i++) {
    charCode = item.charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) {
      realLength += 1;
    } else {
      // 如果是中文则长度加2
      realLength += 2;
    }
  }
  if (realLength > 80) {
    return false;
  } else {
    return true;
  }
};

/*
    echarts 数据排序
*/
Vue.prototype.$Sequence = (property, norm_name) => {
  return function(a, b){
    var value1 = a.cates.filter(itm => {
      if(itm.cate_name == norm_name) {
        return itm[property];
      }
    })
    var value2 = b.cates.filter(itm => {
      if(itm.cate_name == norm_name) {
        return itm[property];
      }
    })
    return value2[0][property] - value1[0][property];
  }
}
/*
    导入
*/
Vue.prototype.$importFile = (e, name) => {
  if (!e.target.files[0]) {
    return false;
  }
  let file = e.target.files[0];
  let formData = new FormData();
  store.state.fullscreenLoading = true;

  let successFunc = null; //导入成功事件
  let path = ""; //定义导入路径
  if (name === "testRecord") {
    formData.append("file", file);
    path = "/p/materialtest/import";
    successFunc = res => {
      router.push({ path: `/home?uuid=${router.history.current.query.uuid}` });
      router.push({
        path: `/selectionSet/NewTestRecord?uuid=${router.history.current.query.uuid}&isEditDialog=true`
      });
    };
  } else if (name === "indexSetting") {
    //岗位设置
    formData.append("file", file);
    path = "/p/norm/import";
    successFunc = res => {
      router.push({
        path: `${router.history.current.path}?infotime=${new Date().getTime()}`
      });
    };
  } else if (name === "planEnty") {
    //体能录入导入
    formData.append("file", file);
    formData.append("uuid", router.history.current.query.uuid);

    path = "/p/train/import";
    successFunc = res => {
      router.push({
        path: `${router.history.current.path}?uuid=${router.history.current.query.uuid
          }&infotime=${new Date().getTime()}`
      });
    };
  } else if (name === "enginery") {
    // 测试管理和状态监控-机能状态-导入
    formData.append("file", file);
    formData.append("uuid", router.history.current.query.uuid);
    path = "/p/test/import";
    successFunc = res => {
      router.push({
        path: `${router.history.current.path}?uuid=${router.history.current.query.uuid
          }&infotime=${new Date().getTime()}`
      });
    };
  }else if (name === "bodyComposition") {
    // 身体成分
    formData.append("file", file);
    formData.append("uuid", router.history.current.query.uuid);
    path = "/p/BodyTest/import";
    
    successFunc = res => {
      router.push({
        path: `${router.history.current.path}?uuid=${router.history.current.query.uuid
          }&infotime=${new Date().getTime()}`
      });
    };
  }else {
    // 人员管理导入
    formData.append("department_uuid", router.history.current.query.uuid);
    formData.append("file", file);
    path = "/p/staff/import";
    successFunc = res => {
      router.push({
        path: `${router.history.current.path}?uuid=${router.history.current.query.uuid
          }&infotime=${new Date().getTime()}`
      });
    };
  }

  axios.post(path, formData).then(res => {
    if (res.data.code == 0) {
      utils.message();
      store.state.fullscreenLoading = false;
      successFunc();
      location.reload()
    } else {
      utils.message({
        type: "error",
        message: res.data.message
      });
      store.state.fullscreenLoading = false;
    }
  });
  e.target.value = "";
};


//模板文件下载
Vue.prototype.$down = (path, uuid) => {
  if (uuid) {
    window.open(`${store.state.url}${path}?uuid=${uuid}`);
  } else {
    window.open(`${store.state.url}${path}`);
  }
};
// 模板导出
Vue.prototype.$expor = (path, uuid) => {
  if (uuid) {
    window.open(`${store.state.url}${path}?uuid=${uuid}`);
  } else {
    window.open(`${store.state.url}${path}`);
  };

}
/*
    限制输入数字小数
*/
Vue.directive("enterNumbers", {
  inserted(el) {
    el.addEventListener("keyup", function (e) {
      e = e || window.event;
      e.target.value = e.target.value.replace(/[^\-?\d.]/g, "");
    });
  }
});
//echarts  自适应问题
Vue.prototype.$echartsResize = function (ref, el) {
  window.addEventListener("resize", function () {
    ref.resize();
  });
};
/*穿梭框全局组件*/
import treeTansfer from "@/components/treeTransfer.vue";
Vue.component("treeTansfer", treeTansfer);

/*防止表单多次提交*/
/*
    el-button 上添加 v-no-more-click
*/
Vue.directive("noMoreClick", {
  inserted(el, binding) {
    el.addEventListener("click", e => {
      el.classList.add("is-disabled");
      el.disabled = true;
      setTimeout(() => {
        el.disabled = false;
        el.classList.remove("is-disabled");
      }, 3000);
    });
  }
});

Vue.prototype.$lastDate = function () {
  let myDate = new Date();
  myDate.setMonth(myDate.getMonth() - 1);
  return (
    myDate.getFullYear() +
    "-" +
    (myDate.getMonth() + 1) +
    "-" +
    myDate.getDate()
  );
};

//获取去年今天
Vue.prototype.$lastYear = function () {
  var nowDate = new Date();
  var date = new Date(nowDate);
  date.setDate(date.getDate() - 365);
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
};
// 设置cookie
Vue.prototype.setCookie = function (name, value, day) {
  //使用this.setCookie("XXXX",res.token)
  var Days = day || 365 //这里设置天数
  var exp = new Date()
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
  document.cookie =
      name + '=' + escape(value) + ';expires=' + exp.toGMTString()
  // document.cookie = name + "="+ escape (value) + ";domain=(这里可以放入指定使用的域名);expires=" + exp.toGMTString();
}
// 获取cookie
Vue.prototype.getCookie = function (cName) {
  /*
   * 使用
   * console.log(this.getCookie("XXX"))
   */
  if (document.cookie.length > 0) {
      let cStart = document.cookie.indexOf(cName + '=')
      if (cStart !== -1) {
          cStart = cStart + cName.length + 1
          let cEnd = document.cookie.indexOf(';', cStart)
          if (cEnd === -1) cEnd = document.cookie.length
          return unescape(document.cookie.substring(cStart, cEnd))
      }
  }
}


// 弹框高度问题
Vue.prototype.$dialogHeight = dialogMaxHeight;

function dialogMaxHeight() {
  const height = document.documentElement.clientHeight * 0.7;
  const width = document.documentElement.clientWidth;
  let elDialog = document.getElementsByClassName("el-dialog");
  for (let i = 0; i < elDialog.length; i++) {
    if (elDialog[i].getElementsByClassName("el-dialog__body")[0]) {
      elDialog[i].getElementsByClassName(
        "el-dialog__body"
      )[0].style.cssText = `max-height:${height}px!important`;
    }
  }
}

// 为了防止下拉列表显示的数据不存在下拉列表中而显示uuid的问题
Vue.prototype.$joinArray = function (showArray, allArray) {
  showArray.forEach((value, index, array) => {
    var flag = false;
    allArray.some((v, i, a) => {
      if (value.uuid == v.uuid) {
        flag = true;
      }
    });
    if (!flag) {
      allArray.push(value);
    }
  });
};

//request拦截器
axios.interceptors.request.use(
  config => {
    config.crossDomain = true;
    config.withCredentials = true;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
let responseNum = true;
//axios response拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code == 100 && responseNum) {
      utils.message({
        type: "error",
        message: "请先登录"
      });
      responseNum = false;
      router.push({ path: "/login", query: { toUrl: router.history.current.path} });
    } else if (response.data.code != 100) {
      responseNum = true;
      return response;
    } else if (response.data.code == 100) {
      responseNum = false;
      router.push({ path: "/login", query: { toUrl: router.history.current.path} });
    }
  },
  error => {
    if (error.response) {
      console.log(error.response);
    }
  }
);

// router.afterEach( ( to, from, next ) => {
//     setTimeout(()=>{
//             var _hmt = _hmt || [];
//             (function() {
//                 //每次执行前，先移除上次插入的代码
//                 document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
//                 var hm = document.createElement("script");
//                 hm.src = "https://hm.baidu.com/hm.js?4a006e57e3e3752e31bebfd05c21ee17";
//                 hm.id = "baidu_tj"
//                 var s = document.getElementsByTagName("script")[0];
//                 s.parentNode.insertBefore(hm, s);
//                 console.log(s)
//             })();
//     },0);
// } );

var _hmt = _hmt || [];
window._hmt = _hmt; // 将_hmt挂载到window下
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?4a006e57e3e3752e31bebfd05c21ee17";
  var s = document.getElementsByTagName("script")[0];
  //  var s = document.getElementsByTagName("script")[document.getElementsByTagName("script").length - 1];
  s.parentNode.appendChild(hm);
})();

router.beforeEach((to, from, next) => {
  if (_hmt) {
    if (to.path) {
      _hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
