import { render, staticRenderFns } from "./headInformation.vue?vue&type=template&id=f648455a&scoped=true&"
import script from "./headInformation.vue?vue&type=script&lang=js&"
export * from "./headInformation.vue?vue&type=script&lang=js&"
import style0 from "./headInformation.vue?vue&type=style&index=0&id=f648455a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f648455a",
  null
  
)

export default component.exports